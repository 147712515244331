// Route connecting all the components after signUp or login having sidebar
//  for navigation.
import React, { useEffect } from "react";
import Sidebar from "../../Shared/sidebar/sidebar";
import Dashboard from "./dashboard/dashboard";
import Styles from "./application.module.scss";
import ManageApps from "./manage-apps/manageApps";
import Report from "./report/report";
import ProductCatalog from "./product-catalog/productCatalog";
import Billing from "./billing/billing";
import AppInfo from "./manage-apps/app-info/appInfo";
import { Switch, Route, useLocation } from "react-router-dom";
import AllPlans from "./billing/all-plans/allPlans";
import IdleTimerContainer from "../../Shared/inactivity-tracker/inactivityTracker";
import { callApi } from "../../api/fetch";
import BatchService from "./batch-service/batch-service";
import AppSettings from "./manage-apps/app-settings/app-settings";
import { ONBOARDING_STATUS } from "../../constants/onboardingStatuses";
import TrialCenter from "./trial-center/trial-center";
import TopBar from "../../Shared/top-bar/topBarNew";
import WarningBar from "./dashboard/warning-bar/warningBar";
import { useHistory } from "react-router-dom";

function Application() {
  const org_id = localStorage.getItem("organisation_id");
  const user: any = localStorage.getItem("@user");
  const userInfo = JSON.parse(user);
  const location = useLocation();
  const history = useHistory();

  const [toggleCollapsedView, setToggleCollapsedView] = React.useState(false);

  const [onboardingStatus, setOnboardingStatus] = React.useState("");
  const [batchActivated, setBatchActivated] = React.useState(false);
  const [accountBalance, setAccountBalance] = React.useState(9000);
  const [alertBalance, setAlertBalance] = React.useState(0);
  const [userDetails, setUserDetails] = React.useState<any>({});
  // getting org info api
  useEffect(() => {
    callApi(`/organisation/${org_id}`, {
      method: "GET",
    }).then((response) => {
      const { onboarding_status, alert_balance } = response;
      setOnboardingStatus(onboarding_status);
      setAlertBalance(alert_balance);
    });

    callApi(`/productBatchService/getBatchTypes/${org_id}`, {
      method: "GET",
    })
      .then((response) => {
        if (response?.length) {
          setBatchActivated(true);
        }
      })
      .catch(() => {
        setBatchActivated(false);
      });

    callApi(`/user/${userInfo._id}`, {
      method: "GET",
    })
      .then((response) => {
        if (response) {
          setUserDetails(response);
        }
      })
      .catch(() => {
        setUserDetails(null);
      });
  }, [org_id]);

  useEffect(() => {
    if (userDetails.email && userInfo.email) {
      if (
        userInfo.email.toLowerCase().trim() !=
        userDetails.email.toLowerCase().trim()
      ) {
        localStorage.removeItem("token");
        localStorage.removeItem("organisation_id");
        history.replace("/login");
      }
    }
  }, [userDetails]);

  useEffect(() => {
    async function getOrgBalance() {
      try {
        const res = await callApi("/summary", {
          method: "GET",
        });
        setAccountBalance(res.wallet_balance);
      } catch (err) {
        console.log(err);
      }
    }

    getOrgBalance();
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className={Styles.wrapper}>
        <div
          className={`d-none d-xl-block ${
            toggleCollapsedView ? Styles.collapseSideBar : Styles.expandSideBar
          }`}
          style={{ position: "relative" }}
        >
          <Sidebar
            collapsedView={toggleCollapsedView}
            toggleCollapseSideBar={() =>
              setToggleCollapsedView(!toggleCollapsedView)
            }
            onboardingStatus={onboardingStatus}
            batchActivated={batchActivated}
          />
        </div>
        <div
          className={`${
            toggleCollapsedView
              ? Styles.collapsePlayground
              : Styles.expandPlayground
          }`}
        >
          <TopBar userDetails={userDetails} />
          <IdleTimerContainer>
            <Switch location={location}>
              <Route path="/application/dashboard" component={Dashboard} />
              <Route path="/application/reports" component={Report} />
              <Route
                path="/application/manage-apps/:appId/settings"
                component={AppSettings}
              />
              <Route
                path="/application/manage-apps/:appId"
                component={AppInfo}
              />

              <Route path="/application/manage-apps" component={ManageApps} />

              <Route path="/application/:id/plans" component={AllPlans} />
              <Route
                path="/application/product-catalog"
                component={ProductCatalog}
              />
              <Route path="/application/billing" component={Billing} />
              {onboardingStatus === ONBOARDING_STATUS.PLAN_SUBSCRIBED &&
              batchActivated ? (
                <Route
                  path="/application/batch-service"
                  component={BatchService}
                />
              ) : null}
              <Route path="/application/trial-center" component={TrialCenter} />
            </Switch>
          </IdleTimerContainer>
          {accountBalance < alertBalance && <WarningBar />}
        </div>
      </div>
    </div>
  );
}

export default Application;
