import React from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import Button from "../../../../../Shared/button/button";
import { primaryButtonStylePopup } from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";

function DocumentProcessorPopup({ handleResponse, name }: any) {
  const errorObject = {
    documentFileError: false,
    consentError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [uploadedFile, setUploadedFile] = React.useState<any>("");
  const handleFileUpload = async (documentFile: any, consent: string) => {
    const documentSideEncoded: any = await toBase64(documentFile);

    const char = ",";
    const frontIndex = documentSideEncoded.indexOf(char);
    const document_file =
      frontIndex !== -1 ? documentSideEncoded.substring(frontIndex + 1) : "";

    handleResponse({ document_file, consent }, setLoading);
  };

  function toBase64(image: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  function handleCheck() {
    if (!uploadedFile) {
      setError(() => ({
        ...error,
        documentFileError: true,
      }));
      return false;
    } else if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    const consent = isChecked === true ? "Y" : "N";
    handleFileUpload(uploadedFile, consent);
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Running Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>
              Document File
            </label>
            <input
              id="id-input-file-ocr"
              type="file"
              accept=".png,.jpeg,.jpg,.tif,.tiff"
              onChange={(e: any) => setUploadedFile(e.target.files[0])}
              className={Styles.fileUpload}
            />
            {error.documentFileError && (
              <ErrorMessage>Please upload a file</ErrorMessage>
            )}
          </div>
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => setIsChecked(e.target.checked)}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-ocr"
              hoveredStyle={primaryButtonStylePopup}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentProcessorPopup;
