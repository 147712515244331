//User having previous history of balance plans and current and plans on hold(if any) will be displayed
import React, { useEffect, useCallback } from "react";
import Styles from "./billing.module.scss";
import Button from "../../../Shared/button/button";
import EmptyState from "../../../Shared/empty-state/emptyState";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import Information from "../../../Shared/svg/information";
import { PRIMARYCOLOR, ACCENTCOLOR } from "../../../Shared/colors";
import RupeeSVG from "../../../Shared/svg/rupeeSVG";
import BankDetailsPopUp from "./bank-detail-pop-up/bankDetailsPopUp";
import EmailSentPopUp from "./email-sent-pop-up/emailSentPopUp";
import KycDetailsPopUp from "./kyc-details-pop-up/kycDetailsPopUp";
import Toast from "../../../Shared/toast/toast";
import { LARGE } from "../../../Shared/buttonSize";
import emptyStateStyle from "../../../Shared/empty-state/emptyState.module.scss";
import { noContent } from "../../../constants/emptyStatesVariables";
import { callApi } from "../../../api/fetch";
import Loading from "../../../Shared/loading/loading";

import ContactSalesRequestPopUp from "./contact-sales-request-pop-up/contactSalesRequestPopUp";
import Tooltip from "../../../Shared/tooltip/tooltip";
import EditAlertBalancePopUp from "./edit-alert-balance-pop-up/editAlertBalancePopUp";
import {
  addGST,
  numberWithCommas,
  roundOfDecimal,
} from "../../../utils/updateAmpunt";
import { monthName } from "../../../utils/getMonth";
import { requestTypes } from "../../../constants/requestTypes";
import { paymentTypes } from "../../../constants/orderTypes";
import { useHistory } from "react-router";
import { ONBOARDING_STATUS } from "../../../constants/onboardingStatuses";
import { useTitle } from "../../../Shared/useTitle";
import DataTable from "../../../Shared/data-table/datatable";
import ProductTable from "./current-plan-product-pricing-pop-up/currentPlanProductPricingPopUp";

function Billing() {
  const planTypes = {
    PREPAID: "PREPAID",
    POSTPAID: "POSTPAID",
  };
  const [
    toggleRequestBalanceForInternationalCustomer,
    setToggleRequestBalanceForInternationalCustomer,
  ] = React.useState(false);
  const [
    toggleEditAlertBalancePopUp,
    setToggleEditAlertBalancePopUp,
  ] = React.useState(false);
  const [toggleBankDetailsPopUp, setToggleBankDetailsPopUp] = React.useState(
    false
  );
  const [toggleEmailSentPopUp, setToggleEmailSentPopUp] = React.useState(false);
  const [toggleKycDetailsPopUp, setToggleKycDetailsPopUp] = React.useState(
    false
  );
  const [bankDetails, setBankDetails] = React.useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
    IFSC: "",
    amount: "",
    amountLabel: "",
    paymentMode: "",
    noteText: "",
    buttonText: "",
  });
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [planSubscripitionLoading, setPlanSubscriptionLoading] = React.useState(
    false
  );
  const [planSubscriptions, setPlanSubscriptions] = React.useState([]);
  const [paymentsLoading, setPaymentsLoading] = React.useState(false);
  const [paymentData, setPaymentData] = React.useState([]);
  const [orgPlanDetails, setOrgPlanDetails] = React.useState<any>();
  const [isPlanSubscribedByOrg, setIsPlanSubscribedByOrg] = React.useState(
    false
  );
  const [overAllLoading, setOverAllLoading] = React.useState(false);
  const [
    fetchVirtualAccountLoading,
    setFetchVirtualAccountLoading,
  ] = React.useState(false);
  const [addBalanceLoading, setAddBalanceLoading] = React.useState(false);
  const [orgDetails, setOrgDetails] = React.useState<any>();

  const [subscribedProduct, setSubscribedProduct] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  // function handleDownloadInvoice(value: any) {
  //   console.log(value);
  // }

  const [currentPlanPopUp, setcurrentPlanPopUp] = React.useState(false);
  const [
    currentplandetailsLoding,
    setcurrentplandetailsLoding,
  ] = React.useState(false);

  const [currentPricing, setCurrentPricing] = React.useState<any>([]);

  const history = useHistory();

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  const fetchOrgPlanDetails = useCallback(async () => {
    try {
      const organisationId = localStorage.getItem("organisation_id");
      const data = await callApi(
        `/organisation/${organisationId}?action=get_org_plan_info`,
        {
          method: "GET",
        }
      );
      if (data?.current) {
        setIsPlanSubscribedByOrg(true);
        setOrgPlanDetails((orgPlanDetails: any) => ({
          ...orgPlanDetails,
          current: data?.current,
          next: null,
        }));
      }
      if (data?.next) {
        const next = data?.next;
        const { start_date } = data?.next;
        const date = new Date(Number(start_date));
        const formatted_date = `${monthName(
          date.getMonth()
        )} ${date.getDate()}, ${date.getFullYear()}`;
        setOrgPlanDetails((orgPlanDetails: any) => ({
          ...orgPlanDetails,
          next: { ...next, start_date: formatted_date },
        }));
      }
      setOverAllLoading(false);
    } catch (err) {
      toastTimeout(true, "error", (err as Error).message);
      setOverAllLoading(false);
    }
  }, []);

  useEffect(() => {
    async function getOrgDetails() {
      try {
        const data = await callApi(
          `/organisation/${localStorage.getItem(
            "organisation_id"
          )}?action=get_org_details`,
          {
            method: "GET",
          }
        );
        if (data.onboarding_status !== ONBOARDING_STATUS.PLAN_SUBSCRIBED) {
          history.push("/application/dashboard");
        }
        setOrgDetails(data);
      } catch (err) {
        toastTimeout(true, "error", (err as Error).message);
      }
    }

    getOrgDetails();
  }, [history]);

  useEffect(() => {
    setOverAllLoading(true);
    fetchOrgPlanDetails();
  }, [fetchOrgPlanDetails]);

  useEffect(() => {
    // Fetch Plan Subscriptions
    async function fetchPlanSubscriptions() {
      try {
        setPlanSubscriptionLoading(true);
        const organisationId = localStorage.getItem("organisation_id");
        const data = await callApi(
          `/organisation/${organisationId}?action=get_org_plan_subscriptions`,
          {
            method: "GET",
          }
        );
        // latest start_date first sort
        data.sort((olderActivityLogs: any, mostRecentActivityLogs: any) => {
          return (
            mostRecentActivityLogs.start_date - olderActivityLogs.start_date
          );
        });
        setPlanSubscriptions(data);
        setPlanSubscriptionLoading(false);
      } catch (err) {
        toastTimeout(true, "error", (err as Error).message);
        setPlanSubscriptionLoading(false);
      }
    }

    fetchPlanSubscriptions();
  }, []);

  useEffect(() => {
    // Fetch current Plan  details
    async function fetchcurrentplandetails() {
      try {
        setcurrentplandetailsLoding(true);
        const planId = orgPlanDetails?.current?.plan_id;
        if (planId) {
          const data = await callApi(`/plan/${planId}`, { method: "GET" });
          if (data) {
            setCurrentPricing(data.pricing);
          }
        }
        setcurrentplandetailsLoding(false);
      } catch (err) {
        toastTimeout(true, "error", (err as Error).message);
        setcurrentplandetailsLoding(false);
      }
    }
    fetchcurrentplandetails();
  }, [orgPlanDetails]);

  useEffect(() => {
    // Fetch current subscribed products
    async function fetchSubscribedProducts() {
      try {
        setIsLoading(true);

        const data = await callApi(`/product?subscription_status=subscribed`, {
          method: "GET",
        });
        setSubscribedProduct(data);

        setIsLoading(false);
      } catch (err) {
        toastTimeout(true, "error", (err as Error).message);
        setIsLoading(false);
      }
    }
    fetchSubscribedProducts();
  }, [currentPricing]);

  useEffect(() => {
    // Fetch Payments data
    async function fetchPaymentsData() {
      try {
        setPaymentsLoading(true);
        const organisationId = localStorage.getItem("organisation_id");
        const data = await callApi(
          `/organisation/${organisationId}?action=get_org_past_payments`,
          {
            method: "GET",
          }
        );
        // latest payment_created_at first sort
        data.sort((olderActivityLogs: any, mostRecentActivityLogs: any) => {
          return (
            new Date(mostRecentActivityLogs.payment_created_at).valueOf() -
            new Date(olderActivityLogs.payment_created_at).valueOf()
          );
        });
        setPaymentData(data);
        setPaymentsLoading(false);
      } catch (err) {
        toastTimeout(true, "error", (err as Error).message);
        setPaymentsLoading(false);
      }
    }
    fetchPaymentsData();
  }, []);

  async function addBalanceForInternationalCustomer() {
    setAddBalanceLoading(true);
    try {
      await callApi("/requests", {
        method: "POST",
        data: {
          request_type: requestTypes.ADD_BALANCE_REQUEST,
        },
      });
      setAddBalanceLoading(false);
      toastTimeout(true, "success", "Your request has been registered");
      setToggleRequestBalanceForInternationalCustomer(false);
    } catch (err) {
      setAddBalanceLoading(false);
      toastTimeout(true, "error", (err as Error).message);
      setToggleRequestBalanceForInternationalCustomer(false);
    }
  }

  async function requestAddBalance() {
    setAddBalanceLoading(true);
    try {
      await callApi("/events?action=add_balance_request", {
        method: "POST",
        data: {
          balance_amount: 10000,
        },
      });
      setAddBalanceLoading(false);
      setToggleBankDetailsPopUp(false);
      setToggleEmailSentPopUp(true);
    } catch (err) {
      toastTimeout(true, "error", (err as Error).message);
      setAddBalanceLoading(false);
      setToggleBankDetailsPopUp(false);
    }
  }

  async function fetchVirtualAccountDetails() {
    setFetchVirtualAccountLoading(true);
    try {
      const organisationId = localStorage.getItem("organisation_id");
      const response = await callApi(
        `/organisation/${organisationId}/virtual-account`,
        {
          method: "GET",
        }
      );
      setFetchVirtualAccountLoading(false);
      setToggleBankDetailsPopUp(true);
      setBankDetails((bankDetails: any) => ({
        ...bankDetails,
        accountName: response.name,
        bankName: response.bank_name,
        accountNumber: response.account_number,
        IFSC: response.ifsc,
        paymentMode: "NEFT/IMPS",
        noteText:
          "Every recharge amount will reflect in your wallet balance after the tax deduction on the total amount transferred. Invoice generated later will reflect the same.",
        amount: "5000 - 10,00,000",
        amountLabel: "Recharge Amount",
        buttonText: "Send via Email",
      }));
    } catch (error) {
      setFetchVirtualAccountLoading(false);
    }
  }

  async function onActivateNewPlan() {
    setOverAllLoading(true);
    try {
      const organisationId = localStorage.getItem("organisation_id");
      await callApi(
        `/organisation/${organisationId}?action=org_activate_next_plan`,
        {
          method: "PUT",
        }
      );
      await fetchOrgPlanDetails();
      toastTimeout(true, "success", "You new plan has been activated");
    } catch (err) {
      setOverAllLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  }

  async function onAddAlertBalance(value: number) {
    // setOverAllLoading(true);
    try {
      const organisationId = localStorage.getItem("organisation_id");
      await callApi(
        `/organisation/${organisationId}?action=org_alert_balance_update`,
        {
          method: "PUT",
          data: {
            alert_balance: value,
          },
        }
      );
      await fetchOrgPlanDetails();
      toastTimeout(
        true,
        "success",
        `Your ${
          orgPlanDetails?.current?.plan_type === planTypes.POSTPAID
            ? "daily consumption"
            : "alert balance"
        } has been updated`
      );
    } catch (err) {
      setOverAllLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  }
  useTitle("Zoop Customer Platform | Billing");

  const paymentHistoryHeadersList = [
    {
      name: "Date",
    },
    {
      name: "Payment Type",
    },
    {
      name: "Mode",
    },
    {
      name: "Amount",
    },
  ];

  const planHistoryHeadersList = [
    {
      name: "Plan name",
    },
    {
      name: "Plan type",
    },
    {
      name: "Start date",
    },
    {
      name: "End date",
    },
  ];

  return (
    <div className="pb-5">
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      {overAllLoading ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : isPlanSubscribedByOrg ? (
        <div className="p-3">
          {/* <div className="px-3 pt-2 d-flex align-items-center">
            <div className="ml-auto">
              <Button
                id="z-billing-add-balance-btn"
                size={MEDIUM}
                hoveredStyle={primaryButtonHoverStyle}
                style={primaryButtonStyle}
                disabled={
                  orgPlanDetails?.current?.plan_type === planTypes.POSTPAID
                }
                isLoading={fetchVirtualAccountLoading}
                type="submit"
                onClick={() => {
                  if (orgDetails?.country_code !== "IN") {
                    setToggleRequestBalanceForInternationalCustomer(true);
                    return;
                  }
                  fetchVirtualAccountDetails();
                }}
              >
                Add Balance
              </Button>
            </div>
          </div> */}

          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className={"col-xl-12"}>
                <div className="pt-3 pb-2 px-3">
                  <div
                    className={`${Styles.billingCard} ${Styles.cardAnimation} bg-background-white-color`}
                  >
                    <div
                      className={`p-3 d-flex align-items-center ${Styles.cardHeader}`}
                    >
                      <p className={"mb-0 px-2 card_title_bold_text"}>
                        Plan Details
                      </p>
                    </div>

                    <div className="container-fluid p-0">
                      <div className="row no-gutters">
                        <div className="col-md-6">
                          <div className="container-fluid p-0">
                            <div className="row no-gutters">
                              <div className={"col-sm-6"}>
                                <div className="p-3 d-flex align-items-center">
                                  <div className={"px-2"}>
                                    <div className={`${Styles.key} mb-0`}>
                                      Current Plan:
                                    </div>
                                    <div className={`${Styles.value} mb-0`}>
                                      <div className="px-1">
                                        <p
                                          className={`${Styles.currentPlan} mb-0`}
                                          onClick={() =>
                                            setcurrentPlanPopUp(true)
                                          }
                                        >
                                          {orgPlanDetails?.current?.plan_name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={"col-sm-6"}>
                                <div className="p-3 d-flex align-items-center">
                                  <div className={"px-2"}>
                                    <p className={`mb-0 ${Styles.key}`}>
                                      Validity:
                                    </p>
                                    <p className={`mb-0 ${Styles.value}`}>
                                      {orgPlanDetails?.current?.duration
                                        ? `${orgPlanDetails.current.duration} months`
                                        : "NA"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="container-fluid p-0">
                            <div className="row no-gutters">
                              <div className={"col-sm-6"}>
                                <div className="p-3 d-flex align-items-center">
                                  <div>
                                    <p className={`mb-0 pl-1 ${Styles.key}`}>
                                      {"Available Balance"}
                                    </p>
                                    <div className="d-flex align-items-center">
                                      <div className="pl-1">
                                        <RupeeSVG
                                          color={PRIMARYCOLOR}
                                          width="7"
                                          height="14"
                                          margin="3px"
                                        />
                                      </div>
                                      <div className="px-1">
                                        <p className={`mb-0 ${Styles.value}`}>
                                          {!isNaN(
                                            orgPlanDetails?.current?.balance
                                          ) ||
                                          orgPlanDetails?.current.balance === 0
                                            ? orgPlanDetails?.current
                                                ?.plan_type ===
                                              planTypes.POSTPAID
                                              ? "NA"
                                              : numberWithCommas(
                                                  addGST(
                                                    orgPlanDetails?.current
                                                      .balance
                                                  )
                                                )
                                            : "NA"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {orgPlanDetails?.current?.plan_type ===
                              planTypes.PREPAID ? (
                                <div className={"col-sm-6"}>
                                  <div className="p-3">
                                    <p className={`mb-0 pl-1 ${Styles.key}`}>
                                      {"Alert Balance:"}
                                      <Tooltip
                                        placement="bottom"
                                        title="By default set to 20% of plan amount added in wallet"
                                      >
                                        <span className="px-2">
                                          <Information />
                                        </span>
                                      </Tooltip>
                                    </p>
                                    <div className="d-flex align-items-center">
                                      <div className="pl-1">
                                        <RupeeSVG
                                          color={PRIMARYCOLOR}
                                          width="7"
                                          height="14"
                                          margin="3px"
                                        />
                                      </div>
                                      <div className="px-1">
                                        <p className={`mb-0 ${Styles.value}`}>
                                          {orgPlanDetails?.current
                                            ?.alert_balance
                                            ? numberWithCommas(
                                                roundOfDecimal(
                                                  orgPlanDetails?.current
                                                    .alert_balance
                                                )
                                              )
                                            : 0}
                                        </p>
                                      </div>
                                      {orgPlanDetails?.current?.plan_type ===
                                      planTypes.PREPAID ? (
                                        <div className={"px-1"}>
                                          <p
                                            className={`${Styles.editTextStyle} mb-0`}
                                            onClick={() =>
                                              setToggleEditAlertBalancePopUp(
                                                true
                                              )
                                            }
                                          >
                                            Edit
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Next Plan */}
              {orgPlanDetails?.next ? (
                <div className="col-lg-12">
                  <div className="pt-2 pb-2 px-3">
                    <div
                      className={`${Styles.billingCard} ${Styles.cardAnimation} bg-background-white-color`}
                    >
                      <div
                        className={`p-3 d-flex align-items-center ${Styles.cardHeader}`}
                      >
                        <p className={"mb-0 px-2 card_title_bold_text"}>
                          Next Plan
                        </p>
                        <div className="ml-auto">
                          <div
                            className="d-flex align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="pl-2">
                              {orgPlanDetails?.next.status ===
                              "PAYMENT_RECEIVED" ? (
                                <p
                                  className={`mb-0 ${Styles.changePlanText}`}
                                  onClick={onActivateNewPlan}
                                >
                                  Activate Now
                                </p>
                              ) : (
                                <p className={`mb-0 ${Styles.pendingStatus}`}>
                                  Payment Pending
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-fluid p-0">
                        <div className="row no-gutters">
                          <div className="col-md-6">
                            <div className="container-fluid p-0">
                              <div className="row no-gutters">
                                <div className="col-sm-6">
                                  <div className="p-3 d-flex align-items-center">
                                    <div className={"px-2"}>
                                      <p className={`mb-0 ${Styles.key}`}>
                                        Plan Name:
                                      </p>
                                      <p className={`mb-0 ${Styles.value}`}>
                                        {orgPlanDetails?.next.plan_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="p-3 d-flex align-items-center">
                                    <div>
                                      <p className={`mb-0 pl-1 ${Styles.key}`}>
                                        Balance to be Added:
                                      </p>
                                      <div className="d-flex align-items-center">
                                        <div className="pl-1">
                                          <RupeeSVG
                                            color={PRIMARYCOLOR}
                                            width="7"
                                            height="14"
                                          />
                                        </div>
                                        <div className="px-1">
                                          <p className={`mb-0 ${Styles.value}`}>
                                            {numberWithCommas(
                                              roundOfDecimal(
                                                orgPlanDetails?.next.balance
                                              )
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="container-fluid p-0">
                              <div className="row no-gutters">
                                <div className="col-sm-6">
                                  <div className="p-3 d-flex align-items-center">
                                    <div className={"px-2"}>
                                      <p className={`mb-0 ${Styles.key}`}>
                                        Validity:
                                      </p>
                                      <p className={`mb-0 ${Styles.value}`}>
                                        {orgPlanDetails?.next.duration} Months
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="p-3 d-flex align-items-center">
                                    {orgPlanDetails?.next.status ===
                                    "PAYMENT_RECEIVED" ? (
                                      <div>
                                        <p className={`mb-0 ${Styles.key}`}>
                                          Activation date:
                                        </p>
                                        <p className={`mb-0 ${Styles.value}`}>
                                          {orgPlanDetails?.next.start_date}
                                        </p>
                                      </div>
                                    ) : (
                                      <div>
                                        <p className={`mb-0 ${Styles.key}`}>
                                          Activation Request:
                                        </p>
                                        <p className={`mb-0 ${Styles.value}`}>
                                          {orgPlanDetails?.next
                                            .enable_immediately
                                            ? "On Payment"
                                            : "On Current Plan Expiry"}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Next Plan */}
            </div>
          </div>

          {/* Payment History */}
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-6">
                <div className="py-3 pl-3 pr-2">
                  <DataTable
                    purpose=""
                    headers={paymentHistoryHeadersList}
                    loading={paymentsLoading}
                    rows={paymentData}
                    headerTitle={"Payment History"}
                    bodyClassName={Styles?.bodyClassName}
                    pagination={false}
                  >
                    <>
                      {paymentData.map((payment: any) => {
                        const date = new Date(payment.payment_created_at);
                        return (
                          <tr key={payment.order_id}>
                            <td className={Styles.tableBody}>
                              {`${monthName(
                                date.getMonth()
                              )} ${date.getDate()}, ${date.getFullYear()}`}
                            </td>
                            <td className={Styles.tableBody}>
                              {
                                paymentTypes.find(
                                  (paymentType) =>
                                    paymentType.key === payment?.payment_type
                                )?.value
                              }
                            </td>
                            <td className={Styles.tableBody}>
                              {payment.mode ?? "NA"}
                            </td>
                            <td className={Styles.tableBody}>
                              <div className="d-flex align-items-center">
                                <div className="pr-1">
                                  <RupeeSVG
                                    color={PRIMARYCOLOR}
                                    width="7"
                                    height="14"
                                  />
                                </div>
                                {numberWithCommas(
                                  roundOfDecimal(payment.paid_amount)
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </DataTable>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="py-3 pr-3 pl-2">
                  <DataTable
                    purpose=""
                    headers={planHistoryHeadersList}
                    loading={planSubscripitionLoading}
                    rows={planSubscriptions}
                    headerTitle={"Plan History"}
                    bodyClassName={Styles?.bodyClassName}
                    pagination={false}
                  >
                    <>
                      {planSubscriptions.map((plan: any) => {
                        const start_date = new Date(Number(plan.start_date));
                        const end_date = new Date(Number(plan.end_date));
                        return (
                          <tr key={`${plan.plan_code} ${plan.createdAt}`}>
                            <td className={Styles.tableBody}>
                              {plan.plan_name}
                            </td>
                            <td className={Styles.tableBody}>
                              {plan.plan_type === planTypes.PREPAID
                                ? "Prepaid"
                                : "Postpaid"}
                            </td>
                            <td className={Styles.tableBody}>
                              {`${monthName(
                                start_date.getMonth()
                              )} ${start_date.getDate()}, ${start_date.getFullYear()}`}
                            </td>
                            <td className={Styles.tableBody}>
                              {plan.end_date
                                ? `${monthName(
                                    end_date.getMonth()
                                  )} ${end_date.getDate()}, ${end_date.getFullYear()}`
                                : "NA"}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ height: "calc(100vh - 66px)" }}
          className="d-flex align-items-center justify-content-center"
        >
          <EmptyState type={noContent}>
            <div className={"py-2"}>
              <p className={`${emptyStateStyle.emptyStateHeadingText} mb-0`}>
                Subscribe to a plan to view the Billing information here.
              </p>
            </div>
            <div className="py-3">
              <p className={`mb-0 ${emptyStateStyle.emptyStateBodyText}`}>
                This space will keep you updated about your Subscriptions and
                Balance history once you have subscribed for a plan with us.
              </p>
            </div>
          </EmptyState>
        </div>
      )}
      {toggleBankDetailsPopUp ? (
        <BankDetailsPopUp bankDetails={bankDetails}>
          <div className="pt-4 text-center" style={{ margin: "0 auto" }}>
            <Button
              id="z-billing-btn-req-addbalance"
              hoveredStyle={primaryButtonHoverStyle}
              style={primaryButtonStyle}
              size={LARGE}
              isLoading={addBalanceLoading}
              onClick={() => {
                requestAddBalance();
              }}
            >
              {bankDetails.buttonText}
            </Button>
            <div className="py-3 d-flex justify-content-center">
              <p
                className={`mb-0 ${Styles.cancelText} d-inline-flex`}
                onClick={() => setToggleBankDetailsPopUp(false)}
              >
                Cancel
              </p>
            </div>
          </div>
        </BankDetailsPopUp>
      ) : null}
      {toggleEmailSentPopUp ? (
        <EmailSentPopUp onConfirm={() => setToggleEmailSentPopUp(false)} />
      ) : null}
      {toggleKycDetailsPopUp ? (
        <KycDetailsPopUp
          onCancel={() => setToggleKycDetailsPopUp(false)}
          onSendOverEmail={() => {
            setToggleKycDetailsPopUp(false);
            setToggleEmailSentPopUp(true);
          }}
        />
      ) : null}
      {toggleRequestBalanceForInternationalCustomer ? (
        <ContactSalesRequestPopUp
          isLoading={addBalanceLoading}
          onConfirm={() => addBalanceForInternationalCustomer()}
          onCancel={() =>
            setToggleRequestBalanceForInternationalCustomer(false)
          }
        />
      ) : null}
      {toggleEditAlertBalancePopUp && (
        <EditAlertBalancePopUp
          currentPlanType={orgPlanDetails?.current?.plan_type}
          onCancel={() => setToggleEditAlertBalancePopUp(false)}
          onUpdate={(value: string) => {
            setToggleEditAlertBalancePopUp(false);
            onAddAlertBalance(Number(value));
          }}
        />
      )}
      {currentPlanPopUp && (
        <ProductTable
          pricing={currentPricing}
          onCancel={() => setcurrentPlanPopUp(false)}
          Loding={currentplandetailsLoding}
          subscribedProduct={subscribedProduct}
        />
      )}
    </div>
  );
}

export default Billing;
